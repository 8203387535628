










































import 'leaflet/dist/leaflet.css';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import L, {Marker, Polygon, Polyline} from 'leaflet';
import mapIconLocation from '@/assets/images/map/map-marker-location.png';
import mapIconCustomer from '@/assets/images/map/map-marker-customer.png';
import mapIconUser from '@/assets/images/map/map-marker-user.png';
import mapIconDefault from '@/assets/images/map/map-marker-default.png';
import ToggleButtonGroupComponent from '@/components/shared/ToggleButtonGroup.component.vue';
import Address from '@/models/Address';
import GeoPosition from '@/models/GeoPosition';
import {GeometryTypes} from '@/misc/Enums/Constants.ts';



const tenantStore = namespace('tenant');
const CustomerStore = namespace('customer');
const UserStore = namespace('user');

@Component({
  components: {
    ToggleButtonGroupComponent,
    MapFilterComponent: () => import(
        '@/components/map/MapFilter.component.vue'),
  },
})
export default class AddressManageMapLocationComponent extends Vue {

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: () => new Address()})
  public address!: Address;

  @Prop({required: true})
  public staticGeoObjects!: Array<{ index: number, geoPosition: GeoPosition }>;

  @Prop({required: true})
  public selectedGeoObject!: { index: number, geoPosition: GeoPosition };
  public geoPositionCopy: GeoPosition = new GeoPosition();

  public staticMapObjects: Array<Polygon | Polyline | Marker> = [];

  /**
   * initial coordinates of the map center, defaults to center of germany
   */
  public initialCoords = {lat: 51.1638175, lng: 10.4478313};

  /**
   * Map config values
   */
  public selectedMode: GeometryTypes = GeometryTypes.POINT;
  public mapConfig: any = {
    url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
    bounds: [],
    markerObjects: [],
    zoom: 4,
  };
  public mapMarker: Marker[] = [];
  public mapObject?: Polyline | Polygon;
  private MARKER_ICON_SIZE: number = 75 / 2;
  private map: L.Map | undefined;
  private zoom: number = 4;

  /**
   * These icons are used to show the elements on the map.
   * To change the color, download the svg file of the map marker from here: https://materialdesignicons.com/.
   * Add 'fill' Property after '<path' and chose a color. rgb() is allowed too.
   * After changing, convert the file to png.
   */
  private iconType = {
    location: mapIconLocation,
    customer: mapIconCustomer,
    user: mapIconUser,
    default: mapIconDefault,
  };

  private buttons = [
    {text: this.$t('LOCATION_MANAGE.GEO_OBJECT.POINT').toString(), value: GeometryTypes.POINT},
    {text: this.$t('LOCATION_MANAGE.GEO_OBJECT.LINESTRING').toString(), value: GeometryTypes.LINESTRING},
    {text: this.$t('LOCATION_MANAGE.GEO_OBJECT.POLYGON').toString(), value: GeometryTypes.POLYGON},
  ];

  public async saveLocation() {
    this.$emit('created', this.geoPositionCopy);
  }

  /**
   * function to get the zoom according to the given information of the address
   */
  public getZoom(): number {
    // zoom in if the selected Object is a diversion and there is a Location for the address
    if (this.selectedGeoObject.index > 0 && this.staticGeoObjects.findIndex((value) => value.index === 0) >= 0) {
      return 18;
    }
    // zoom depending on known values
    if (this.address.houseNo) {
      return 18;
    }
    if (this.address.street) {
      return 16;
    }
    if (this.address.postalCode) {
      return 15;
    }
    if (this.address.city) {
      return 12;
    }
    return 6;
  }

  /**
   * Initialise the map component
   */
  public async mounted() {
    // lock diversions to linestring
    if (this.selectedGeoObject.index > 0) {
      this.selectedMode = GeometryTypes.LINESTRING;
    } else {
      this.selectedMode = this.selectedGeoObject.geoPosition.type;
    }

    // change the initial coords and zoom if there is a geolocation present
    if (this.selectedGeoObject.geoPosition?.positions && this.selectedGeoObject.geoPosition?.positions.length) {
      this.initialCoords = this.selectedGeoObject.geoPosition.getLatLngOfFirst();
      this.zoom = 18;
    } else {
      if (this.address.geoPosition?.positions.length) {
        this.initialCoords = this.address.geoPosition!.getLatLngOfFirst();
      }
      this.zoom = this.getZoom();
    }

    // load map
    this.map = await L.map('worldMap').setView(this.initialCoords, this.zoom);
    await L.tileLayer(this.mapConfig.url, {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
    this.buildObjects();
    this.initMapListener();
    this.map.invalidateSize();
  }

  /**
   *   build the other geoobjects which are not edited right now
   */
  public buildStaticGeoObjects() {
    const initialObjects: Array<{ index: number, geoPosition: GeoPosition }> = [];
    this.staticGeoObjects?.forEach((value) => {
      if (value.geoPosition.positions.length > 0) {
        initialObjects.push({index: value.index, geoPosition: value.geoPosition.copy()});
      }
    });
    initialObjects.forEach((geoObject) => {
      let geo: Marker | Polygon | Polyline;
      switch (geoObject.geoPosition.type) {
        case GeometryTypes.LINESTRING:
          geo = L.polyline(geoObject.geoPosition.positions, {color: geoObject.index === 0 ? 'red' : 'green'})
              .bindTooltip(geoObject.index === 0 ?
                  this.$t('GENERAL.LOCATION').toString() :
                  (this.$t('GENERAL.DIVERSION').toString() + ' ' + geoObject.index.toString()))
              .openTooltip();
          geo.addTo(this.map!);
          break;
        case GeometryTypes.POINT:
          geo = L.marker(geoObject.geoPosition.positions[0]).setIcon(this.getIcon(true))
              .bindTooltip(geoObject.index === 0 ?
                  this.$t('GENERAL.LOCATION').toString() :
                  (this.$t('GENERAL.DIVERSION').toString() + ' ' + geoObject.index.toString()))
              .openTooltip();
          geo.addTo(this.map!);
          break;
        default:
          geo = L.polygon(geoObject.geoPosition.positions, {color: geoObject.index === 0 ? 'red' : 'green'})
              .bindTooltip(geoObject.index === 0 ?
                  this.$t('GENERAL.LOCATION').toString() :
                  (this.$t('GENERAL.DIVERSION').toString() + ' ' + geoObject.index.toString()))
              .openTooltip();
          geo.addTo(this.map!);
          break;
      }
      this.staticMapObjects.push(geo);
    });
  }

  /**
   * function to create the editable geoObject with all its functions
   */
  public buildGeoObject() {
    for (const coords of this.geoPositionCopy.positions) {
      const marker = L.marker(coords, {
        riseOffset: 7,
        draggable: true,
        // on drag on Marker, change position of the marker
      }).setIcon(this.getIcon(false)).on('drag', (dragEvent: any) => {
        const index = this.mapMarker.findIndex((mark) => dragEvent.target === mark);
        this.geoPositionCopy.positions[index] = this.mapMarker[index].getLatLng();
        this.updatePolyObject(this.geoPositionCopy.type);
        // on click on Marker, delete it
      }).on('click', (dragEvent: any) => {
        const index = this.mapMarker.findIndex((point) => dragEvent.target === point);
        this.mapMarker[index].remove();
        this.mapMarker.splice(index, 1);
        this.geoPositionCopy.positions.splice(index, 1);
        this.updatePolyObject(this.geoPositionCopy.type);
      });
      this.mapMarker.push(marker);
      marker.addTo(this.map!);
    }
    this.updatePolyObject(this.geoPositionCopy.type);
  }

  @Watch('selectedMode')
  public onModeChange() {
    this.flushMarkers();
  }

  /**
   * build all existing markers and lines on the map
   */
  public buildObjects() {
    if (this.selectedGeoObject.geoPosition.positions.length > 0) {
      this.geoPositionCopy = GeoPosition.parseFromObject(this.selectedGeoObject.geoPosition.parseToObject());
      this.buildGeoObject();
    }
    if (this.staticGeoObjects.length !== 0) {
      this.buildStaticGeoObjects();
    }
  }

  /**
   * function to remove all markers from the map
   */
  public flushMarkers() {
    this.mapMarker.forEach((layer) => layer.remove());
    this.mapMarker = [];
    this.geoPositionCopy.positions = [];
    this.mapObject?.remove();
    this.geoPositionCopy.type = this.selectedMode;

  }

  /**
   * clickEvents of the Map
   */
  public initMapListener() {
    // create a Marker on the Map
    this.map!.on('click', async (clickEvent: any) => {
      const coords = clickEvent.latlng;
      if (this.selectedMode === GeometryTypes.POINT) {
        this.flushMarkers();
      }
      this.geoPositionCopy.positions.push({lat: coords.lat, lng: coords.lng});
      const marker = L.marker(coords, {
        riseOffset: 7,
        draggable: true,
        // on drag on Marker, change position of the marker
      }).setIcon(this.getIcon(false)).on('drag', (dragEvent: any) => {
        const index = this.mapMarker.findIndex((mark) => dragEvent.target === mark);
        this.geoPositionCopy.positions[index] = this.mapMarker[index].getLatLng();
        this.updatePolyObject(this.selectedMode);
        // on click on Marker, delete it
      }).on('click', (dragEvent: any) => {
        const index = this.mapMarker.findIndex((mark) => dragEvent.target === mark);
        this.mapMarker[index].remove();
        this.mapMarker.splice(index, 1);
        this.geoPositionCopy.positions.splice(index, 1);
        this.updatePolyObject(this.selectedMode);
      });
      this.mapMarker.push(marker);
      marker.addTo(this.map!);
      this.updatePolyObject(this.selectedMode);
    });
  }

  /**
   * function to update the polyObject
   */
  public updatePolyObject(mode: GeometryTypes) {
    this.mapObject?.remove();
    if (mode === GeometryTypes.LINESTRING) {
      this.mapObject = L.polyline(this.geoPositionCopy.positions).addTo(this.map!);
    } else if (mode === GeometryTypes.POLYGON) {
      this.mapObject = L.polygon(this.geoPositionCopy.positions).addTo(this.map!);
    }
  }

  /**
   * Get the icon of the given object. The icon depends of the type of the object
   */
  private getIcon(isOther: boolean) {
    // const scaleQuotient = type === 'customer' ? this.MAP_ICON_SCALE_QUOTIENT - 0.2 : this.MAP_ICON_SCALE_QUOTIENT;
    // get the icon. It depends on the type of the object. Get the type via 'constructor.name'
    return L.icon({
      iconUrl: isOther ? this.iconType.default : this.iconType.location,
      iconSize: [this.MARKER_ICON_SIZE, this.MARKER_ICON_SIZE],
      iconAnchor: [this.MARKER_ICON_SIZE / 2, this.MARKER_ICON_SIZE - 3],
    });
  }
}
