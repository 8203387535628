










import {Component, VModel, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class ToggleButtonGroupComponent extends Vue {

  @Prop({default: undefined})
  public buttons!: Array<{ text: string, value: string }>;

  @VModel({required: true, type: String})
  private selectedFilter!: string;

  private valuesForButtons: Array<{ text: string, value: string }> = [];

  public created() {
    // Default values in case buttons has no values
    this.valuesForButtons = this.buttons ? this.buttons : [
      {text: this.$t('GENERAL.ALL').toString(), value: 'all'},
      {text: this.$t('GENERAL.ACTIVE').toString(), value: 'active'},
      {text: this.$t('GENERAL.INACTIVE').toString(), value: 'inactive'},
    ];
    if (!this.selectedFilter) {
      this.selectedFilter = this.valuesForButtons[0].value;
    }
  }

  private onClick(value: string) {
    this.selectedFilter = value;
  }

  private getColorClass(value: string): string {
    return this.selectedFilter === value ? 'button-active' : '';
  }
}
