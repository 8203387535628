
























































































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {validationMixin} from 'vuelidate';
import {maxLength, numeric, required} from 'vuelidate/lib/validators';
import Location from '@/models/Location';
import Customer from '@/models/Customer';
import User from '@/models/User';
import {namespace} from 'vuex-class';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {locationStoreActions} from '@/stores/location.store';
import {userStoreActions, userStoreGetter} from '@/stores/user.store';
import {authStoreGetter} from '@/stores/auth.store';
import {customerStoreActions} from '@/stores/customer.store';
import AddressManageMapLocationComponent from '@/components/map/AddressManageMapLocation.component.vue';
import MapOverviewView from '@/views/map/MapOverview.view.vue';
import GeoPosition from '@/models/GeoPosition';
import TopicsListComponent from '@/components/shared/TopicsList.component.vue';
import {getTrafficAreas, TrafficAreas} from '@/misc/Enums/Constants';
import UploadFile from '@/interfaces/UploadFile';
import DBFile from '@/interfaces/DBFile';
import {FindAllResponse} from '@/interfaces/FindAllResponse';
import MenuWithPickerComponent from '@/components/shared/MenuWithPicker.component.vue';
import moment from 'moment';
import Diversion from '@/models/Diversion';
import axios from 'axios';

const UserStore = namespace('user');
const LocationStore = namespace('location');
const AuthStore = namespace('auth');
const CustomerStore = namespace('customer');

@Component({
  mixins: [validationMixin],
  validations: {
    location: {
      name: {required},
      managerId: {required},
      trafficArea: {required},
      emergencyPhone: {numeric},
      projectId: {required},
      startDate: {required},
      endDate: {required},
      address: {
        houseNo: {maxLength: maxLength(15)},
        postalCode: {numeric, maxLength: maxLength(5)},
      },
      customer: {
        name: {required},
        phone: {required, numeric},
        orderId: {required},
        accountantName: {required},
      },
    },
  },
  components: {
    MenuWithPickerComponent,
    TopicsListComponent,
    AddressManageMapLocationComponent,
    MapOverviewView,
    UserInitialsComponent: () => import(
        /* webpackChunkName: "UserInitialsComponent" */
        '@/components/user/UserInitials.component.vue'),
    RJTextField: () => import(
        '@/components/shared/custom-vuetify/RJTextField.vue'),
    RJAutocomplete: () => import(
        '@/components/shared/custom-vuetify/RJAutocomplete.vue'),
    RJSelect: () => import(
        '@/components/shared/custom-vuetify/RJSelect.vue'),
    RJChip: () => import(
        '@/components/shared/custom-vuetify/RJChip.vue'),
    SideCardComponent: () => import(
        '@/components/shared/SideCard.component.vue'),
    UploadDataComponent: () => import(
        '@/components/shared/UploadData.component.vue'),
  },
})
/**
 * The locationManageComponent without sideCard and its title. Separated for CreateJobComponent.
 */
export default class LocationManageContentComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({default: undefined})
  public locationId!: string;
  @Prop({default: undefined})
  public selectedCustomer!: Customer;
  @UserStore.Getter(userStoreGetter.USERS)
  private users!: User[];
  @AuthStore.Getter(authStoreGetter.USER)
  private _user!: User;
  @LocationStore.Action(locationStoreActions.LOAD_LOCATION_ACTION)
  private loadLocationAction!: (payload: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @CustomerStore.Action(customerStoreActions.LOAD_CUSTOMER_ACTION)
  private loadCustomerAction!: (payload: { customerId: string, shouldBeStored: boolean }) => Promise<Customer>;
  @LocationStore.Action(locationStoreActions.CREATE_LOCATION_ACTION)
  private createLocationAction!: (payload: { location: Location, shouldBeStored: boolean }) => Promise<Location>;
  @LocationStore.Action(locationStoreActions.EDIT_LOCATION_ACTION)
  private editLocationAction!: (payload: { location: Location, shouldBeStored: boolean }) => Promise<Location>;
  @UserStore.Action(userStoreActions.LOAD_USERS_ACTION)
  private loadUsersAction!: (payload: { tenantId: string, relations?: string[] }) => Promise<User[]>;
  @LocationStore.Action(locationStoreActions.CREATE_FILE_ACTION)
  private createFileAction!: (payload: { locationId: string, files: FormData }) => Promise<FindAllResponse<DBFile>>;
  @LocationStore.Action(locationStoreActions.DELETE_DIVERSION_ACTION)
  private deleteDiversionAction!: (diversionId: string) => Promise<void>;
  /**
   * location entity that is edited or created.
   */
  public location!: Location;

  /**
   * Min max values for date start picker
   */
  public dateStartMinMax: { min: string, max: string } = {
    min: moment().toISOString(),
    max: '',
  };
  /**
   * Min max values for date end picker
   */
  public dateEndMinMax: { min: string, max: string } = {
    min: moment().toISOString(),
    max: '',
  };

  /**
   * current GeoPosition that is editable in the Map
   */
  public selectedGeoObject: { index: number, geoPosition: GeoPosition } = {index: 0, geoPosition: new GeoPosition()};

  /**
   * suggestions for addresses in the Street combobox
   */
  private addressItems: any[] = [];

  /**
   * flag to skip the Load , which normally happens when changing the searchstring
   */
  private skipLoad: boolean = true;
  /**
   * the String in the Address.street Combobox for the OSM suggestions
   */
  private searchString: string = '';

  public diversionToDelete?: Diversion;

  /**
   * Where all Geopositions of this location are stored
   * index = 0 this is the address Geoposition
   * index < 0 Geoposition of diversions
   * The is used to show other Geopositions in the map when working on one
   */
  public geoObjectCache: Array<{ index: number, geoPosition: GeoPosition }> = [];

  /**
   * currently modified geolocation, number as above
   */
  public currentIndex: number = 0;


  public isEditMode: boolean = false;
  public hasSameName: boolean = false;
  public showDeleteDiversionDialog: boolean = false;
  public showMapDialog: boolean = false;
  public customer: Customer = new Customer();

  public trafficAreas: TrafficAreas[] = getTrafficAreas();

  /**
   * possible pdf files from .files
   */
  public uploadFiles: UploadFile[] = [];


  /**
   * is true when loading data from OpenStreetMaps
   */
  private isLoading: boolean = false;
  private searchBarDebounce: number = 0;


  constructor() {
    super();
    this.location = new Location();
    this.location.diversions.push(new GeoPosition());
  }

  // for incoming filters through permissions
  public get availableUsers(): User[] {
    return this.users;
  }

  public addDiversion(index: number) {
    this.location.diversions.push(new Diversion());
  }

  /**
   * This function is called when the user changes the location Address via the combobox suggestions.
   * Changes the Address and updated the geolocation for the map
   * @param event the selected input from the combobox
   */
  public async searchInput(event: any) {
    if (typeof event === 'object') {
      this.skipLoad = true;
      // set address to location
      await this.setAddress(event);

      // update the geolocation in the cache
      const arrayIndex = this.geoObjectCache.findIndex((value) => value.index === 0);
      const newGeoPosition: GeoPosition = new GeoPosition();
      newGeoPosition.positions = [{lat: event.lat, lng: event.lon}];
      if (arrayIndex >= 0) {
        this.geoObjectCache.splice(arrayIndex, 1, {index: 0, geoPosition: newGeoPosition});
      } else {
        this.geoObjectCache.push({index: 0, geoPosition: newGeoPosition});
      }
      this.location.address!.geoPosition = newGeoPosition;
      this.addressItems = [];
    }
  }

  /**
   * set the address of the location according to the selection
   */
  public setAddress(event: any) {
    this.searchString = event.address.road;
    this.location.address!.street = event.address.road;
    this.location.address!.postalCode = event.address.postcode;
    this.location.address!.houseNo = event.address.house_number;
    this.location.address!.city = this.getCity(event.address);
  }

  public getCity(address: any): string {
    if (address.city) {
      return address.city;
    } else if (address.town) {
      return address.town;
    } else if (address.village) {
      return address.village;
    } else {
      return address.suburb;
    }
  }


  public async mounted() {
    try {
      await this.loadUsersAction({tenantId: this.$route.params.tenantId});
      await this.watchLocation(this.locationId);
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  /**
   * Event handler for on start date change. Sets the date start display value and real cleanTime.date value.
   */
  public onDateStartChange(date: string) {
    this.triggerValidation('location.startDate');
    this.location.startDate = moment(date).set('hour', moment().hour()).toISOString(false);
    if (moment().format('L') === moment(date).format('L')) {
      this.location.startDate =  moment(date).set('hour', moment().hour()).toISOString(false);
    } else {
      this.location.startDate = moment(date).toISOString(false);
    }
    this.dateEndMinMax.min = this.location.startDate;
  }

  /**
   * Event handler for on end date change. Sets the date end display value and real cleanTime.date value.
   */
  public onDateEndChange(date: string) {
    this.triggerValidation('location.endDate');
    this.location.endDate = moment(date).endOf('day').subtract(1, 'second').toISOString(false);
    this.dateStartMinMax.max = this.location.endDate;
  }

  /**
   * Function to delete a Diversion. Checks if the Diversion is new or was already existing.
   * Already existing diversions need proper deletion in the backend.
   * new Diversions just need local deletion
   * @param diversion diversion to delete
   * @param index index of the Diversion in the Cache
   */
  public onDeleteDiversionClick(diversion: Diversion, index: number) {
    if (!diversion.id) {
      this.location.diversions.splice(index, 1);
      const arrayIndex = this.geoObjectCache.findIndex((value) => value.index === index + 1);
      if (arrayIndex >= 0) {
        this.geoObjectCache.splice(arrayIndex, 1);
      }
    } else {
      this.currentIndex = index;
      this.diversionToDelete = diversion;
      this.showDeleteDiversionDialog = true;
    }
  }

  /**
   * proper diversion delete via api call
   */
  public async onDeleteDiversion() {
    this.showDeleteDiversionDialog = false;
    try {
      await this.deleteDiversionAction(this.diversionToDelete?.id!);
      this.location.diversions.splice(this.currentIndex, 1);
      const arrayIndex = this.geoObjectCache.findIndex((value) => value.index === this.currentIndex + 1);
      this.geoObjectCache.splice(arrayIndex, 1);
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DIVERSION_DELETE.SUCCESS');
    } catch {
      this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DIVERSION_DELETE.ERROR');
    }
  }

  /**
   * function which is called before opening the map. Selects the right geoposition to edit
   * @param index index of the geolocation. 0 for address. >0 for diversions
   */
  public async editAddressGeoCode(index: number) {
    this.currentIndex = index;
    const arrayIndex = this.geoObjectCache.findIndex((value) => value.index === this.currentIndex);

    // if the address is new, get the default coords for the map
    if (index === 0 && arrayIndex < 0) {
      try {
        await axios.get(
            'https://nominatim.openstreetmap.org/search.php?format=jsonv2',
            {
              params: {
                street: this.searchString ? (this.location.address!.houseNo ?? '') + ' ' + this.searchString
                    : null,
                country: 'Germany',
                postalcode: this.location.address!.postalCode ?? null,
                city: this.location.address!.city ?? null,
                addressdetails: 1,
              },
            },
        ).then((results: any) => {
          const bestGuess = results.data[0] ?? null;
          if (bestGuess) {
            this.location.address!.geoPosition!.positions = [{lat: bestGuess.lat, lng: bestGuess.lon}];
          }
        });
      } catch (e) {
        this.$notifyErrorSimplified('LOCATION_MANAGE.NOTIFICATIONS.LOADING_MAP_ERROR');
      }
    }
    // if the geolocation exists, get it from the cache
    if (arrayIndex >= 0) {
      this.selectedGeoObject = {index, geoPosition: this.geoObjectCache.splice(arrayIndex, 1)[0].geoPosition.copy()};
    } else {
      this.selectedGeoObject = {index, geoPosition: new GeoPosition()};
    }
    this.skipLoad = true;
    this.showMapDialog = true;
  }

  /**
   *  function when editing a geolocation is cancelled get the old values back
   */
  public onCloseMap() {
    if (this.currentIndex === 0) {
      this.location.address!.geoPosition = this.selectedGeoObject.geoPosition;
    }
    if (this.selectedGeoObject.geoPosition.positions.length) {
      this.geoObjectCache.push(this.selectedGeoObject);
    }
    // reset the geoObject
    this.selectedGeoObject = {index: 0, geoPosition: new GeoPosition()};
    this.showMapDialog = false;
  }

  /**
   * function when the geolocation was successfully edited. updates the currently selected geolocation
   * @param geoPosition the edited geoposition from the map
   */
  public onGeoObjectEdited(geoPosition: GeoPosition) {
    const geo = geoPosition.copy();
    this.showMapDialog = false;
    if (this.currentIndex === 0) {
      this.location.address!.geoPosition = geo.copy();
    } else {
      this.location.diversions[this.currentIndex - 1].geoPosition = geo.copy();
    }
    this.geoObjectCache.push({index: this.currentIndex, geoPosition: geo.copy()});
  }

  public getUserFullName(user: User): string {
    return user.fullName;
  }

  public onCancel() {
    this.hasSameName = false;
    this.$emit('cancel');
  }

  public async onSubmit() {

    // trigger validation
    this.$v.$touch();

    if (this.$v.$invalid) {
      // if invalid scroll to first error input
      // OPTIMIZE the use of css selector of a vuetify property is not always safe
      requestAnimationFrame(() => this.$vuetify.goTo('.error--text', {offset: 50}));
      this.$notifyErrorSimplified('GENERAL.MISSING_DATA');
    } else {
      if (!(this.location.address?.street || this.location.address?.geoPosition?.positions.length)) {
        this.$notifyErrorSimplified('LOCATION_MANAGE.NOTIFICATIONS.NO_ADDRESS');
        return;
      }
      try {
        // work with copy and perform preprocessing
        const locationCopy: Location = this.location.copy();
        delete locationCopy.manager;
        delete locationCopy.files;
        delete locationCopy.jobs;
        delete locationCopy.workSessions;
        locationCopy.diversions = locationCopy.diversions.filter((diversion) => diversion.geoPosition?.positions.length! > 0);

        if (this.isEditMode) {
          const editedLocation = await this.editLocationAction({
            location: locationCopy,
            shouldBeStored: true, // is stored from loadLocationAction due to done emit
          });
          await this.createFiles(editedLocation.id!, this.uploadFiles);
          this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.SUCCESS');
          this.$emit('done', this.location);
        } else {
          locationCopy.tenantId = this.$route.params.tenantId;
          const createdLocation = await this.createLocationAction({
            location: locationCopy,
            shouldBeStored: false, // is stored from loadLocationAction due to done emit
          });
          await this.createFiles(createdLocation.id!, this.uploadFiles);
          this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_CREATE.SUCCESS');
          this.$emit('done', createdLocation);
        }
      } catch (e) {
        // Check if we get the status 409 for having the same name more than once, show error as modal and in edit field
        if (this.isEditMode && e.status === 409) {
          this.$notifyErrorSimplified(`CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.${e.status}`);
          this.hasSameName = true;
        } else if (!this.isEditMode && e.status === 409) {
          this.$notifyErrorSimplified(`CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_CREATE.${e.status}`);
          this.hasSameName = true;
        } else if (this.isEditMode) {
          this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.ERROR');
        } else {
          this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_CREATE.ERROR');
        }

        // Scroll to First Error Message
        requestAnimationFrame(() => this.$vuetify.goTo('.error--text', {offset: 50}));
      }
    }
  }

  /**
   * function to create the pdf files in the backend
   * @param locationId ID of the location
   * @param files files to create
   */
  public async createFiles(locationId: string, files?: UploadFile[]) {
    const documents = new FormData();
    let notEmpty = false;
    if (files && files.length !== 0) {
      for (const file of files) {
        if (!file.id) {
          documents.append('file', file.data);
          notEmpty = true;
        }
      }
      if (notEmpty) {
        await this.createFileAction({locationId, files: documents});
      }
    }
  }

  public getUserId(user: User): string {
    return user.id!;
  }

  /**
   * Checking the user search input in the street field
   */
  @Watch('searchString')
  private async onSearchStreetChanged() {
    this.location.address!.street = this.searchString;
    if (!this.skipLoad) {
      window.clearTimeout(this.searchBarDebounce);
      this.searchBarDebounce = window.setTimeout(async () => {
        await this.addressByStreet(this.searchString);
      }, 600);
    }
    this.skipLoad = false;
  }

  /**
   * Searching the street, returning the address
   * @param street
   */
  private async addressByStreet(street: string): Promise<void> {
    if (!street) {
      return;
    }
    // Start loading
    this.isLoading = true;

    try {
      const {data} = await axios.get(
          'https://nominatim.openstreetmap.org/search.php?format=jsonv2',
          {
            params: {
              street,
              country: 'Germany',
              addressdetails: 1,
            },
          },
      );
      this.addressItems = data;
    } catch (e) {
      this.$notifyErrorSimplified('LOCATION_MANAGE.NOTIFICATIONS.LOADING_MAP_ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  @Watch('locationId')
  private async watchLocation(location: string) {
    this.hasSameName = false;
    if (location) {
      this.isEditMode = true;
      this.skipLoad = true;
      this.location = await this.loadLocationAction({locationId: this.locationId, shouldBeStored: false});
      this.customer = this.location.customer as Customer;
      this.searchString = this.location.address!.street ?? '';
      this.uploadFiles = [];
      if (this.location.files) {
        for (const file of this.location.files) {
          this.uploadFiles.push({
            id: file.id,
            data: new File([file.data.buffer], file.name),
          });
        }
      }
      // fills the geolocation cache with the existing geolocations
      this.geoObjectCache = [];
      this.geoObjectCache.push({index: 0, geoPosition: this.location.address?.geoPosition!});
      this.location.diversions.forEach((diversion, index) => {
        this.geoObjectCache.push({index: index + 1, geoPosition: diversion.geoPosition!.copy()});
      });
    } else {
      this.isEditMode = false;
      this.location = new Location();
    }
  }
  public getAddressCoords() {
    return ' ( '
        + Math.floor(this.location.address!.geoPosition!.getLatLngOfFirst().lat * 100) / 100
        + ', '
        +  Math.floor(this.location.address!.geoPosition!.getLatLngOfFirst().lng * 100) / 100
        + ' )';
  }
}
