






































































import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class MenuWithPickerComponent extends Vue {

  private readonly TIME_MENU_WIDTH = 200;

  /**
   * Min max values for date start picker
   */
  @Prop({default: () => [{min: '', max: ''}]})
  public dateMinMax!: { min: string, max: string };
  /**
   * The label to be displayed
   */
  @Prop({default: '', type: String})
  public label!: string;
  /**
   * Should it be a datePicker or a timePicker
   */
  @Prop({default: 'date'})
  public type!: 'date' | 'time';
  @Prop({default: false})
  public clearable!: boolean;
  @Prop({default: false})
  public showAdjacent!: boolean;
  @Prop({default: ''})
  public errorMessages!: string | string[];
  @Prop({default: false})
  public alternativeDesign!: boolean;
  @Prop({default: true})
  public showCurrent!: boolean;
  /**
   * Menu flag for date menu
   */
  private menuFlag: boolean = false;

  private pickerValue: string = '';

  /**
   * The date from the datePicker as ISOString
   */
  @VModel({required: true})
  public _returnValue!: string;

  private get returnValue(): string {
    return this._returnValue;
  }

  private set returnValue(value: string) {
    let returnValue = value;
    if (this.type === 'date') {
      returnValue = moment(value).toISOString();
    }
    this._returnValue = returnValue;
  }
  /**
   * Humanized date string
   */
  private get displayValue(): string {
    if (this.isValid(this.returnValue)) {
      if (this.type === 'date') {
        this.pickerValue = moment(this.returnValue).format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString());
        return moment(this.returnValue).format(this.$t('GENERAL.DATE_FORMATS.DATE').toString());
      } else {
        return this.returnValue;
      }
    }
    return '';
  }

  /**
   * This is executed if the textField is not readonly. In this case it is used in timePicker mode only
   * @param value
   * @private
   */
  private set displayValue(value: string) {
    this.returnValue = value;
  }
  /**
   * Method to fill the timepicker-list with half-hour times
   */
  public get givenMinutes(): string[] {
    const times: string[] = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 2; m++) {
        times.push(`${h < 10 ? 0 : ''}${h}:${m ? '' : 0}${m * 30}`);
      }
    }
    return times;
  }

  /**
   * Removes the inputs from the dateEnds
   */
  public removeValues() {
    this.returnValue = '';
    this.pickerValue = '';
    this.openMenu();
    this.$emit('change');
  }

  private onInputChange(pickerValue: string) {
    if (pickerValue && pickerValue.length > 0) {
      this.returnValue = pickerValue;
      this.menuFlag = false;
      this.$emit('change');
    }
  }

  private isValid(value: string): boolean {
    return !!value && value.trim() !== '';
  }

  /**
   * Formats the returned and displayed value of the textField in timepicker mode, when blurred
   * @private
   */
  private formatTime() {
    // do it if no errors occurred and the string has no maximum length of 5
    if (this.type === 'time' && this.returnValue && this.returnValue.length > 0 && this.returnValue.length < 5) {
      const parts = this.returnValue.split(':');
      if (parts.length === 1) {
        parts.push('');
      }
      // add a 0 in case the number is a single digit so something like 2:3 is possible and means 02:03
      // 4: --> 04:00 is possible too or even 4
      this.returnValue = parts.map((part) => part.length < 2 ? part.length === 0 ? '00' : `0${part}` : part).join(':');
    }
  }

  private get isClearable() {
    return !!(this.clearable && this.returnValue);
  }

  private openMenu() {
    (this.$refs.menuTextField as any).$refs.input.click();
  }
}
