




















import {Component, VModel} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import Tenant from '@/models/Tenant';
import {namespace} from 'vuex-class';
import {validationMixin} from 'vuelidate';
import {minLength, required} from 'vuelidate/lib/validators';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {tenantStoreGetter} from '@/stores/tenant.store';
import {getTopics, Topics} from '@/misc/Enums/Topics';


const TenantStore = namespace('tenant');
const CustomerStore = namespace('customer');
@Component({
  mixins: [validationMixin],
  validations: {
    topics: {
      required,
      minLength: minLength(1),
    },
  },
})
export default class TopicsListComponent extends mixins(ErrorMessageHandlerMixin) {
  @TenantStore.Getter(tenantStoreGetter.ACTIVE_TENANT)
  public _tenant!: Tenant;
  @VModel({required: true})
  public topics!: Topics[];

  public allTopics = getTopics(true);

  private get getMenuProps() {
    return {
      closeOnClick: false,
      closeOnContentClick: false,
      disableKeys: true,
      openOnClick: false,
      maxHeight: 304,
      offsetY: true,
      bottom: true,
      rounded: 'lg',
      offsetOverflow: true,
      nudgeBottom: 10,
      contentClass: 'custom-form-select-menu',
    };
  }

  get topicArray(): any[][] {
    return [[
      {
        name: this.allTopics[0],
        icon: require('../../assets/images/TopicIcons/Sign.svg'),
        active: this.topics.includes(this.allTopics[0]),
      },
      {
        name: this.allTopics[1],
        icon: require('../../assets/images/TopicIcons/Beacon.svg'),
        active: this.topics.includes(this.allTopics[1]),
      },
      {
        name: this.allTopics[2],
        icon: require('../../assets/images/TopicIcons/WarningLights.svg'),
        active: this.topics.includes(this.allTopics[2]),
      }], [
      {
        name: this.allTopics[3],
        icon: require('../../assets/images/TopicIcons/Marks.svg'),
        active: this.topics.includes(this.allTopics[3]),
      },
      {
        name: this.allTopics[4],
        icon: require('../../assets/images/TopicIcons/TrafficLights.svg'),
        active: this.topics.includes(this.allTopics[4]),
      },
      {
        name: this.allTopics[5],
        icon: require('../../assets/images/TopicIcons/Others.svg'),
        active: this.topics.includes(this.allTopics[5]),
      }],

    ];
  }

  private inputEvent() {
    this.triggerValidation('topics');
  }

  private toggleTopic(topic: Topics) {
    const index = this.topics.indexOf(topic);
    index === -1 ? this.topics.push(topic) : this.topics.splice(index, 1);

    // mus have atleast one topics
    if (this.topics.length === 0) {
      this.topics.push(topic);
    }
  }



}
